<template>
  <Layout>
    <div class="quienes-somos-restaurante">
      <div
        class="header-restaurante"
        :style="{
          'background-image':
            'url(' + require(`@/assets/images/restaurante-fondo.jpg`) + ')',
        }"
      >
        <div class="nombre-restaurante">{{ restaurante.nombre }}</div>
      </div>

      <div class="contenedor-restaurante">
        <div class="restaurante-descripcion">
          <h1 v-html="nosotros"></h1>

          <p>{{ restaurante.descripcion }}</p>

          <div class="restaurante-informacion">
            <div>
              <h3>Teléfono</h3>
              <p>{{ restaurante.telefono }}</p>
            </div>
            <div>
              <h3>Horarios</h3>
              <span
                v-for="horario in restaurante.horario"
                :key="horario.apertura"
              >
                <p>{{ horario.apertura }} - {{ horario.cierre }}</p>
              </span>
            </div>

            <div>
              <h3>Redes sociales</h3>

              <ul>
                <li>
                  <a href="#">
                    <img src="@/assets/images/facebook.svg" alt="" />
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img src="@/assets/images/whatsapp.svg" alt="" />
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img src="@/assets/images/instagram.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

import Layout from "@/layout/Layout.vue";

export default {
  name: "Quienes somos",
  components: {
    Layout,
  },

  setup() {
    const store = useStore();

    const restaurante = computed(() => store.state.restaurante.restaurante);
      
    var nosotros = store.state.restaurante.restaurante.idiomas.filter( item => item.id === store.state.idiomas.options.locale).map(item => {return item.nosotros});

    return {
      store,
      restaurante,
      nosotros,
    };
  },
};
</script>

<style lang="scss" scoped>
.quienes-somos-restaurante {
  .header-restaurante {
    padding: 25vh 0;
    background: rgb(245, 244, 244);
    background-image: url("/assets/img/restaurante-fondo.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 698px) {
      padding: 150px 0;
    }
  }

  .nombre-restaurante {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    color: #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    text-shadow: 2px 2px 0px #000;
    padding: 10px;

    @media (max-width: 698px) {
      font-size: 22px;
    }
  }

  .contenedor-restaurante {
    width: 1024px;
    margin: 0 auto;
    padding: 50px;
    transform: translateY(-100px);
    background: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);

    @media (max-width: 1024px) {
      width: 90%;
      padding: 25px;
    }
  }

  .restaurante-informacion {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media (max-width: 698px) {
      grid-template-columns: 1fr;
    }

    p {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      margin-right: 15px;
      list-style: none;

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
