<template>
  <Layout>
    <div class="container mobile-m mt-5 mb-5">
      <div class="row">
        <div class="col-md-6">
          <h1 class="ContactoLabel">{{traducciones.find((item)=>item.item == 'Contacto').value}}</h1>
          <p id="ContactoTxt">{{traducciones.find((item)=>item.item == 'ContactoTxt').value}}
            
          </p>
        </div>
        <form @submit="onSubmit" class="col-md-6 bg-form mb-5 shadow ">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="inputNombre" class="form-label">{{traducciones.find((item)=>item.item == 'Nombre').value}}</label>
              <input type="text" class="form-control" name="inputNombre" v-model="inputNombre"/>
            </div>
            <div class="col-md-12 mb-3">
              <label for="inputTelefono" class="form-label">{{traducciones.find((item)=>item.item == 'Telefono').value}}</label>
              <input type="number" class="form-control" name="inputTelefono" v-model="inputTelefono"/>
            </div>
            <div class="col-md-12 mb-3">
              <label for="inputEmail" class="form-label">{{traducciones.find((item)=>item.item == 'Email').value}}</label>
              <input type="email" class="form-control" name="inputEmail" v-model="inputEmail"/>
            </div>
            <div class="col-12 mt-2 mb-3">
              <label for="Mensaje" class="form-label"
                >{{traducciones.find((item)=>item.item == 'Mensaje').value}}</label
              >
              <textarea
                class="form-control"
                name="Mensaje"
                v-model="Mensaje"
                rows="3"
              ></textarea>
            </div>
            <div class="col-12">
              <button id="enviarcomentario" type="submit" class="btn btn-verde w-100">{{traducciones.find((item)=>item.item == 'Enviar').value}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
	import { API_URL } from "../utils/constants";
import { useStore } from "vuex";
import Layout from "@/layout/Layout.vue";
export default {
  components: {
    Layout,
  }
,
methods:{
   onSubmit: async function (e) {
              e.preventDefault();

        var sucursal = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.id;
        try {
        let js = await fetch(API_URL+'/enviarComentarioContacto?sucursal='+sucursal+'&nombre='+this.inputNombre+'&movil='+this.inputTelefono+'&email='+this.inputEmail+'&comentario='+this.Mensaje);
        const res = await js.json()
        if (res.success)
        this.$swal({ position: "center",
                    icon: "success",
                    title: "El mensaje se envió con éxito",
                    showConfirmButton: false,
                    timer: 2000,});
            
     
         else
        this.$swal({ position: "center",
                    icon: "error",
                    title: "Error al enviar el mensaje",
                    showConfirmButton: false,
                    timer: 2000,});
            
         }

        catch (rejectedValue) {
         this.$swal({ position: "center",
                    icon: "error",
                    title: "Error al enviar el mensaje",
                    showConfirmButton: false,
                    timer: 2000,});
        }
  } 
},
setup() {

    const store = useStore();

const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
return {
      traducciones
    };
  },
};

</script>

<style lang="scss" scoped>
.bg-form {
  padding: 25px;
  background: #f3f6f9;
  border-radius: 8px;
  border: 1px solid #ebeff3;
}
</style>
