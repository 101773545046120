<template>
  <div class="tamano-platos">
    <h4 class="mb-3">Tamaños</h4>

    <div
      class="btn-group tamanos"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <input
        type="radio"
        class="btn-check"
        name="tamano-plato"
        id="tamano-plato-1"
        autocomplete="off"
        checked
        value="0"
        @click="agregarValores('Pequeno', 0)"
      />
      <label class="btn btn-outline-primary" for="tamano-plato-1"
        >Pequeño</label
      >

      <input
        type="radio"
        class="btn-check"
        name="tamano-plato"
        id="tamano-plato-2"
        value="1.00"
        autocomplete="off"
        @click="agregarValores('Mediano', 1)"
      />
      <label class="btn btn-outline-primary" for="tamano-plato-2"
        >Mediano +1.00</label
      >

      <input
        type="radio"
        class="btn-check"
        name="tamano-plato"
        id="tamano-plato-3"
        autocomplete="off"
        value="1.50"
        @click="agregarValores('Grande', 1.5)"
      />
      <label class="btn btn-outline-primary" for="tamano-plato-3"
        >Grande +1.50</label
      >

      <input
        type="radio"
        class="btn-check"
        name="tamano-plato"
        id="tamano-plato-4"
        autocomplete="off"
        value="2.00"
        @click="agregarValores('Extra Grande', 2.0)"
      />
      <label class="btn btn-outline-primary" for="tamano-plato-4"
        >Extra grande +2.00</label
      >
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  props: ["productos"],
  setup() {
    const store = useStore();

    const agregarValores = (nombre, precio) => {
      let producto = {
        nombre: nombre,
        precio: precio,
      };

      store.dispatch("productos/setTamanoExtra", producto);
    };

    return { agregarValores };
  },
};
</script>

<style lang="scss" scoped>
.tamano-platos {
  margin-bottom: 30px;
}
.tamanos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .btn-radio {
    background-color: rgba(195, 195, 195, 0.251475);
    border-radius: 8px;

    &.active {
      background: #01662a;
      color: #fff;
      font-weight: 600;
    }
  }
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background: #01662a;
  color: #fff;
  font-weight: bold;
}

label.btn.btn-outline-primary {
  background-color: rgba(195, 195, 195, 0.251475);
  border: 0;
  color: #000;
  padding: 12px 0;
}
</style>