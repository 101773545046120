//https://panel.cartamovil.com/webservices/enviarOpinion?preguntas[0][id]=63&preguntas[0][puntaje]=5&preguntas[1][id]=67&preguntas[1][puntaje]=5&preguntas[2][id]=65&preguntas[2][puntaje]=5&preguntas[3][id]=64&preguntas[3][puntaje]=5&preguntas[4][id]=69&preguntas[4][puntaje]=5&preguntas[5][puntaje]=1&comment=Prueba+Jaime&puesto=336&valora=1
import { API_URL } from "../../utils/constants";

export const resenas = {
    namespaced: true,
    state: {
        loading:false,
        error: false,
    },
    actions: {
        async fetchEncuesta({ commit }, payload) {
            commit("loading", true);
            try {
                console.log(JSON.stringify(payload));
              let response = await fetch(
                `${API_URL}/preguntas?sucursal=${payload.llave}`
              );
      
              let prod = await response.json();
      
              commit("addEncuesta", prod.preguntas);
              commit("loading", false);
            } catch (err) {
              console.log(err); // TypeError: failed to fetch
              commit("addEncuesta", null)
              commit("loading", false);
              commit("error", true);
            }
          },

        async enviarEncuesta({commit}, payload) {
          //https://panel.cartamovil.com/webservices/enviarOpinion?preguntas%5B0%5D%5Bid%5D=63&preguntas%5B0%5D%5Bpuntaje%5D=3&preguntas%5B1%5D%5Bid%5D=67&preguntas%5B1%5D%5Bpuntaje%5D=3&preguntas%5B2%5D%5Bid%5D=65&preguntas%5B2%5D%5Bpuntaje%5D=3&preguntas%5B3%5D%5Bid%5D=64&preguntas%5B3%5D%5Bpuntaje%5D=3&preguntas%5B4%5D%5Bid%5D=69&preguntas%5B4%5D%5Bpuntaje%5D=3&comment=test+desde+app+cartamovil+vue&puesto=336&valora=1
          //forma de enviar los elementos
          // preguntas[0][id]: 63
          // preguntas[0][puntaje]: 3
          // preguntas[1][id]: 67
          // preguntas[1][puntaje]: 3
          // preguntas[2][id]: 65
          // preguntas[2][puntaje]: 3
          // preguntas[3][id]: 64
          // preguntas[3][puntaje]: 3
          // preguntas[4][id]: 69
          // preguntas[4][puntaje]: 3
          // comment: test desde app cartamovil vue
          // puesto: 336
          // valora: 1

          let params = {
            "preguntas[0][id]" : payload.preguntas[0].id,
            "preguntas[0][puntaje]": payload.preguntas[0].puntaje,
            "preguntas[1][id]" : payload.preguntas[1].id,
            "preguntas[1][puntaje]": payload.preguntas[1].puntaje,
            "preguntas[2][id]": payload.preguntas[2].id,
            "preguntas[2][puntaje]" : payload.preguntas[2].puntaje,
            "preguntas[3][id]": payload.preguntas[3].id,
            "preguntas[3][puntaje]": payload.preguntas[3].puntaje,
            "preguntas[4][id]": payload.preguntas[4].id,
            "preguntas[4][puntaje]": payload.preguntas[4].puntaje,
            "comment" : payload.comment,
            "puesto" : 336,
            "valora" : payload.valora,
          }

          let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

          let url = `${API_URL}/enviarOpinion?` + query;

          fetch(url)
            .then(data => data.text())
            .then((text) => {
              console.log('request succeeded with JSON response', text)
            }).catch(function (error) {
              console.log('request failed', error)
              // commit("error", true);
            });
        },

        async enviarComentario({rootState}, payload) {

          let params = {
            sucursal: rootState.idiomas.options.sucursal,
            json: [payload.nombre, payload.correo, payload.telefono]
          }

          let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');
          
          // console.log(query)

          let url = `${API_URL}/enviarDatos?` + query;

          fetch(url)
            .then(data => data.text())
            .then((text) => {
              console.log('request succeeded with JSON response', text)
            }).catch(function (error) {
              console.log('request failed', error)
            });
        }

        
    },
    mutations: {
        addEncuesta(state, payload) {
            state.preguntas = payload;
          },
          loading(state, payload) {
            state.loading = payload;
          },
          error(state, payload) {
            state.error = payload
          }
    },
    getters: {
        OrdenarPorIdioma: (state, _, rootState) => {
          if (state.preguntas) {
            const find = state.preguntas.filter(
              producto => producto.locale === rootState.idiomas.options.locale
            );
            
            return find;
          }
        },
      }
  };
  
