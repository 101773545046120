<template>
  <nav id="sidebar" :class="[mostrarSidebar ? 'open' : '']">
    <div class="sidebar-header">
      <div id="sidebar-imagen">
        <div class="overlay"></div>

        <div class="sidebar-contenedor-imagen">
          <div class="sidebar-avatar">
            <div
              class="avatar-imagen shadow"
              v-if="restaurante.logo == null"
              :style="{
                'background-image':
                  'url(' + require(`@/assets/images/logo.png`) + ')',
              }"
            ></div>
            <div
              class="avatar-imagen shadow"
              v-else
              :style="{
                'background-image':
                  'url(' +
                  `https://${restaurante.path}/uploads/images/sucursales/${restaurante.logo}` +
                  ')',
              }"
            ></div>
            <div class="direccion">
              <h4>{{ restaurante.sucursal }}</h4>
              <p>{{ restaurante.direccion }}</p>
            </div>
          </div>

          <div class="sidebar-horarios">
            <p class="font-weight-bold">Horarios:</p>
            <!-- <span
              v-for="horario in restaurante.horario"
              :key="horario.apertura"
            >
              <p>{{ horario.apertura }}</p>
              <p>{{ horario.cierre }}</p>
            </span> -->
            <span>
              <a :href="`tel:${restaurante.telefono}`">
                <i class="bi bi-telephone-fill"></i
                >{{ restaurante.telefono }}</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-container">
      <ul class="list-unstyled components">
        <li  class="active">
          <router-link id="start" @click="cerrarSidebar()" :to="{ name: 'Home' }"
            >{{traducciones.find((item)=>item.item == 'Inicio').value}}</router-link
          >
        </li>
        <li  class="active">
          <router-link id="quienessomos" @click="cerrarSidebar()" :to="{ name: 'quienes-somos' }"
            >{{traducciones.find((item)=>item.item == 'Quienessomos').value}}</router-link
          >
        </li>
        <li class="active">
          <router-link id="promociones" :to="{ name: 'Promociones' }" @click="cerrarSidebar()"
            >{{traducciones.find((item)=>item.item == 'Promociones').value}}</router-link
          >
        </li>
        <li class="active">
          <router-link id="reservas" :to="{ name: 'reservar' }" @click="cerrarSidebar()"
            >{{traducciones.find((item)=>item.item == 'Reservas').value}}</router-link
          >
        </li>
        <li class="active">
          <router-link id="contacto" :to="{ name: 'Contacto' }" @click="cerrarSidebar()"
            >{{traducciones.find((item)=>item.item == 'Contacto').value}}</router-link
          >
        </li>

        <!-- <li>
          <a
            href="#pageSubmenu"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle"
            >Pages</a
          >
          <ul class="collapse list-unstyled" id="pageSubmenu">
            <li>
              <a href="#">Page 1</a>
            </li>
            <li>
              <a href="#">Page 2</a>
            </li>
            <li>
              <a href="#">Page 3</a>
            </li>
          </ul>
        </li> -->
      </ul>

      <ul class="list-unstyled CTAs">
        <li v-if="(store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Comandero') !== undefined)">
          <a id="login" href="#" class="download">{{traducciones.find((item)=>item.item == 'Login').value}}</a>
        </li>
        <li>
          <router-link id="legal" :to="{ name: 'legal' }" @click="cerrarSidebar()"
            >{{traducciones.find((item)=>item.item == 'Legal').value}}</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
  <div
    @click="cerrarSidebar()"
    id="background-sidebar"
    :class="[mostrarSidebar ? 'open' : '']"
  ></div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const mostrarSidebar = computed(() => store.state.menu.mostrarSidebar);
    const restaurante = computed(() => store.state.restaurante.restaurante);

    const cerrarSidebar = () => {
      store.commit("menu/mostrarSidebar", false);
    };
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return { store, mostrarSidebar, restaurante, cerrarSidebar,traducciones };
  },
};
</script>

<style lang="scss" scoped>
#sidebar {
  position: fixed;
  right: -320px;
  top: 0;
  height: 100%;
  background: #1b6b23;
  color: #fff;
  width: 300px;
  box-shadow: -7px 2px 23px 1px rgba(0, 0, 0, 0.49);
  z-index: 500;
  transition: all 130ms ease-in;
  transform: translateX(0%);

  &.open {
    transform: translateX(-100%);
  }

  .sidebar-container {
    padding: 25px;
  }

  .sidebar-header {
    position: relative;
    height: 175px;
    width: 100%;
  }

  .sidebar-avatar {
    display: flex;
    padding: 13px;
    .avatar-imagen {
      width: 55px;
      height: 50px;
      border-radius: 50%;
      background: #fff;
      margin-right: 12px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .direccion {
      h4 {
        font-size: 12px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      p {
        font-size: 10px;
      }
    }
  }

  #sidebar-imagen {
    background-image: url("~@/assets/images/restaurante.jpg");
    background-position: center;
    height: 175px;
    width: 100%;
  }

  .sidebar-contenedor-imagen {
    position: absolute;
    bottom: 0;
    width: 100%;

    .sidebar-horarios {
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      justify-content: space-evenly;
      font-size: 11px;
      padding-bottom: 10px;

      p {
        margin: 0;
      }

      a {
        color: #fff;
        text-decoration: none;

        i {
          margin-right: 4px;
        }
      }
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  ul {
    a {
      color: #fff;
      padding: 12px;
      display: block;
      text-decoration: none;
    }
  }
}

#background-sidebar {
  &.open {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}
</style>
