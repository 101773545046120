<template>

  <router-view></router-view>
  <Layout>
    <div v-if="productos" class="container-xl mobile-m">
      <Loading v-if="loading" />

      <div
        v-if="!loading"
        :class="[modoMosaico === 'mosaico' ? 'productos-mosaico' : '']"
        class="contenedor-productos"
      >
        <Producto
          v-for="producto in productos"
          :key="producto.id"
          :props="producto"
        />
      </div>
    </div>
    <div v-else class="no-disponible text-center">
      <h3 class="mt-5">{{traducciones.find((item)=>item.item == 'NoHayProductos').value}}</h3>
      <router-link :to="{ name: 'Home' }" class="mt-3"
        >{{traducciones.find((item)=>item.item == 'VolverMenu').value}}</router-link
      >
    </div>
  </Layout>
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "@vue/runtime-core";

import Producto from "@/components/producto/Producto.vue";
import Loading from "@/components/loading/Loading.vue";
import Layout from "@/layout/Layout.vue";
import Filtros from "@/components/buscador/Filtros.vue";

export default {
  name: "Productos",
  components: {
    Producto,
    Layout,
    Loading,
    Filtros
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    onMounted(() => {
      const id = route.params.id;
      store.dispatch("productos/fetchProductos", id);
 
    });

    const productos = computed(
      () => store.getters["productos/OrdenarPorIdioma"]
    );
    
    const loading = computed(() => store.state.productos.loading);
   
    if (store.state.filtros.vista == null && store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'ProductoListado') == undefined)
        store.commit("filtros/filtroVista", 'mosaico');
    else
        if (store.state.filtros.vista == null)
            store.commit("filtros/filtroVista", 'completa');    
    const modoMosaico = computed(() => store.state.filtros.vista);

    watch(
      () => route.params.id,
      (value, prevValue) => {
        
        store.dispatch("productos/fetchProductos", value);
  
      }
    );
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return { productos, loading, modoMosaico,traducciones };
  },
};
</script>

<style>
</style>
